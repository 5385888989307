<template>
  <a-modal :title="modal.title" :visible="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <h3>
      当前导出条件为：
      <a-tooltip placement="bottom" title="点击”查询”更新导出条件">
        <a-icon type="question-circle" class="text-blue-500"></a-icon>
      </a-tooltip>
    </h3>
    <a-form :form="modal.form" class="flex-form a-form-mb-0">
      <a-form-item label="项目">
        {{ modal.data.displayCondition.projectName || '全部' }}
      </a-form-item>
      <a-form-item label="班组">
        {{ modal.data.displayCondition.leaderName || '全部' }}
      </a-form-item>
      <a-form-item label="工种">
        {{ modal.data.displayCondition.workerType || '全部' }}
      </a-form-item>
      <a-form-item label="身份证号">
        {{ modal.data.displayCondition.idCard || '全部' }}
      </a-form-item>
      <a-form-item label="工人姓名">
        {{ modal.data.displayCondition.username || '全部' }}
      </a-form-item>
    </a-form>
    <p class="mt-5">合计：{{ modal.data.displayCondition.totalCount }}条</p>
    <p>是否确认导出？</p>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '确认导出',
        data: {
          displayCondition: {
            totalCount: 0,
          },
          condition: {},
        },
      },
    }
  },
  methods: {
    async start(data) {
      this.resetModal()
      console.log(data)
      this.modal.data = data
      this.modal.visible = true

      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      if (this.modal.data.displayCondition.totalCount === 0) {
        return this.$message.error('导出项数合计不能为0')
      }
      if (this.modal.data.displayCondition.totalCount > 2000) {
        return this.$message.error('导出项数合计大于2000，请联系开发人员导出')
      }
      this.$emit('end', 'ok')
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
